<template>
	<div>
		<div class="outer-wrapper">
			<ProfileHeader
				:firstName = paxFirstName
				:lastName = paxLastName
				:country = "paxCountryOfIssue ? paxCountryOfIssue.name : ''"
				:coverImage = coverImage
				:profileImage = profileImage

				:canEdit = true

				@coverImage="(v) => { coverImage = v }"
				@profileImage="(v) => { profileImage = v }"
			/>

			<div class="inner-wrapper details-form">

				<Loader :loading="isLoading" :error="error"/>

				<div v-if="!isLoading && !error">
					<form class="pax_entry">
						<span class="note">Please enter your details as they appear on your passport</span>
						<section>
							<label for="pax_first_name" class="label">Name</label>
							<input type="text" aria-describedby="pax_first_name_error" v-model="paxFirstName" id="pax_first_name" class="input" maxLength="50">
							<div id="pax_first_name_error" role="alert" v-if="submitted && errors.has('firstName')" class="invalid-feedback">{{ errors.get('firstName')[0] }}</div>


							<label for="pax_last_name" class="label">Last Name</label>
							<input type="text" aria-describedby="pax_last_name_error" v-model="paxLastName" id="pax_last_name" class="input" maxLength="100">
							<div id="pax_last_name_error" role="alert" v-if="submitted && errors.has('lastName')" class="invalid-feedback">{{ errors.get('lastName')[0] }}</div>

							<!-- Semantic HTML: Use fieldset and legend for better structure and accessibility -->
							<fieldset>
								<legend class="label">Gender</legend>
								<div class="gender">
									<!-- ARIA Accessibility: Use aria-describedby to link to error message -->
									<!-- Labeling: Ensure that each input has an associated label -->
									<div class="gender-option">
										<input aria-describedby="gender_error" type="radio" id="male" value="Male" v-model="paxGender" class="input">
										<span class="checkmark"></span>
										<label for="male">Male</label>
									</div>
									<div class="gender-option">
										<input aria-describedby="gender_error" type="radio" id="female" value="Female" v-model="paxGender" class="input">
										<span class="checkmark"></span>
										<label for="female">Female</label>
									</div>
								</div>
								<!-- ARIA Accessibility: Use role="alert" to announce errors -->
								<!-- Link error message to input fields with id matching aria-describedby -->
								<div role="alert" v-if="submitted && errors.has('gender')" class="invalid-feedback" id="gender_error">{{ errors.get('gender')[0] }}</div>
							</fieldset>
						</section>

						<section>
							<h2 class="sub-header">Contact details</h2>

							<label for="pax_email" class="label">Email</label>
							<input type="email" class="email input" v-model="paxEmail" id="pax_email" maxLength="100" aria-describedby="pax_email_error" disabled>
							<div role="alert" v-if="submitted && errors.has('email')" class="invalid-feedback" id="pax_email_error">{{ errors.get('email')[0] }}</div>

							<label for="pax_phone" class="label">Contact Number</label>
							<vue-tel-input 
								id="pax_phone"
								class="input"
								v-model="paxPhone"
								@validate="validatePhone"
								mode="international"
								maxLength="20"
								placeholder=""
								:autoDefaultCountry="true"
								:autoFormat="true"
								aria-describedby="pax_phone_error"
								:dropdownOptions="{
									disabled: false,
									showDialCodeInList: true,
									showDialCodeInSelection: false,
									showFlags: true,
									showSearchBox: false,
									tabindex: 0,
									width: '250px'
								}"
								:inputOptions="{
									placeholder: '',
								}"
							></vue-tel-input>
							<input type="tel" v-model="paxPhone" id="hidden_pax_phone" aria-hidden="true" style="display: none;">
							<div role="alert" v-if="submitted && errors.has('phone')" class="invalid-feedback" id="pax_phone_error">{{ errors.get('phone')[0] }}</div>

						</section>
						<section>
							<h2 class="sub-header">Passport Details</h2>

							<label class="label" for="dob">Date of Birth</label>
							<v-date-picker 
								class="datepicker"
								id="dob"
								v-model="paxDob"
								mode="date" 
								color="customblue"
								:max-date="new Date()"
								:popover="{ visibility: 'click' }"
								aria-describedby="dob_error"
							>
								<template v-slot="{ inputValue, inputEvents, isDragging }">
									<input
										class="input"
										:class="isDragging ? 'is-selecting' : ''"
										:value="inputValue"
										v-on="inputEvents"
									/>
								</template>
							</v-date-picker>
							<div id="dob_error" role="alert" v-if="submitted && errors.has('dob')" class="invalid-feedback number">{{ errors.get('dob')[0] }}</div>

							<label for="pax_passport" class="label">Passport Number</label>
							<input type="text" class="input" v-model="paxPassportNumber" id="pax_passport" maxLength="100" aria-describedby="pax_passport_error">
							<div id="pax_passport_error" role="alert" v-if="submitted && errors.has('passport_number')" class="invalid-feedback">{{ errors.get('passport_number')[0] }}</div>

							<label class="label" for="country">Country of Issue</label>
							<locationDropdown 
								class="locationpicker location"
								id="country"
								v-model="paxCountryOfIssue"
								placeholder=""
								locationType="countries"
								aria-describedby="country_error"
							/>
							<div id="country_error" role="alert" v-if="submitted && errors.has('issue')" class="invalid-feedback">{{ errors.get('issue')[0] }}</div>

							<label class="label" for="expiry">Expiry Date</label>
							<v-date-picker 
								class="datepicker"
								id="expiry"
								v-model="paxPassportExpiry"
								mode="date" 
								color="customblue"
								:min-date="new Date()"
								:popover="{ visibility: 'click' }"
								aria-describedby="expiry_error"
							>
								<template v-slot="{ inputValue, inputEvents, isDragging }">
									<input
										class="input"
										:class="isDragging ? 'is-selecting' : ''"
										:value="inputValue"
										v-on="inputEvents"
									/>
								</template>
							</v-date-picker>
							<div id="expiry_error" role="alert" v-if="submitted && errors.has('expiry')" class="invalid-feedback number">{{ errors.get('expiry')[0] }}</div>
							<div id="related_adult_error" role="alert" v-if="submitted && errors.has('related_adult')" class="invalid-feedback">{{ errors.get('related_adult')[0] }}</div>
						</section>
						<button class="button continue save" @click.prevent="save()" :class="{ disabled: !checkForm }">Save</button> <!-- Save to trip button not functional yet (no custom trips) -->
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>


</style>

<script>
	import Menu from '@/components/Menu.vue'
	import { mapState, mapActions, mapGetters } from 'vuex';
	import router from '@/router';
	import helpers from '@/helpers/helpers.js';
	import { userService } from '@/services/userService.js'
	import account from '@/helpers/account.js';
	import locationDropdown from '@/components/locationDropdown.vue';
	import { PAX_INFANT_MAX_AGE, PAX_ADULT_MIN_AGE } from '@/helpers/constants.js';
	import Loader from '@/components/Loader.vue';
	import ProfileHeader from '@/components/profile/ProfileHeader.vue';

	export default {
		name: 'profileForm',
		data() {
			return {
				submitted: false,
				data: null,

				paxFirstName: "Loading",
				paxLastName: "",
				coverImage: false,
				profileImage: false,
				paxGender: "",
				paxEmail: "",
				paxPhone: "",
				paxPhoneData: {},
				paxPassportNumber: "",
				paxCountryOfIssue: "",
				paxPassportExpiry: "",
				paxDob: "",
				errors: new Map(),

				isLoading: false,
				error: false,
			}
		},
		components: {
			locationDropdown,
			Loader,
			ProfileHeader,
			Menu
		},
		computed: {
			...mapState({
				account: state => state.account,
			}),
			checkForm() {
				this.errors = new Map();
				let firstNameErrors = [];
				let phoneErrors = [];
				var hasNumber = /\d/;
				if(hasNumber.test(this.paxFirstName)) {
					firstNameErrors.push('Name must not contain numbers.');
				}
				let lastNameErrors = [];
				if(hasNumber.test(this.paxLastName)) {
					lastNameErrors.push('Name must not contain numbers.');
				}
				if(this.paxPhone) { // Only trigger this if not empty.
					if(!this.paxPhoneData.valid) {  // Check the validation
						phoneErrors.push('Must be a valid number.');
					}
				}
				if(firstNameErrors.length > 0) {
					this.errors.set('firstName', firstNameErrors);
				}
				if(phoneErrors.length > 0) {
					this.errors.set('phone', phoneErrors);
				}
				
				if (!this.errors.size > 0) {
					return true;
				}
				return false;

			},
		},
		methods: {
			...mapActions({
				alertError: "alert/error",
				alertSuccess: "alert/success",
				saveUserProfile: "account/saveUserProfile",
				fetchUserProfile: "account/fetchUserProfile"
			}),
			validatePhone: function (data) {
				this.paxPhoneData = data;
			},
			formatDate: (date) => helpers.formatDate(date),
			save() {
				this.submitted = true;
				if(this.checkForm) {
					this.isLoading = true;
					let pax_data = {
						firstname: this.paxFirstName,
						lastname: this.paxLastName,
						gender: this.paxGender,
						phone: JSON.stringify({
							countryCallingCode: this.paxPhoneData.countryCallingCode ? this.paxPhoneData.countryCallingCode : "",
							nationalNumber: this.paxPhoneData.nationalNumber ? this.paxPhoneData.nationalNumber : "",
							fullNumber: this.paxPhoneData.number ? this.paxPhoneData.number : "",
						}),
						dob: this.paxDob ? helpers.formatDateISO(new Date(this.paxDob)) : "",
						passport_number: this.paxPassportNumber,
						country_of_issue: this.paxCountryOfIssue ? JSON.stringify(this.paxCountryOfIssue) : "",
						passport_expiry: this.paxPassportExpiry ? helpers.formatDateISO(new Date(this.paxPassportExpiry)) : "",
					};
					// Only add the image data if they are files, not just URLs from existing image
					if(this.coverImage && this.coverImage.name) {
						pax_data.banner_image = this.coverImage;
					}
					if(this.profileImage && this.profileImage.name) {
						pax_data.profile_image = this.profileImage;
					}

					// Push to appropriate endpoint
					this.saveUserProfile(pax_data)
						.then(response => {
							this.alertSuccess("Saved successfully")
							this.isLoading = false;
							router.push({
								name: 'Profile Details',
							});
						},
						error => {
							this.alertError(error)
							this.isLoading = false;
						});
				} else {
					// TODO: scroll to top (to show errors)
				}
			},
		},
		mounted() {
		},
		async created() {
			this.$emit('update:topLayoutSettings', {
				left: {
					type: 'back',
					route: this.$route.query.redirect ? this.$route.query.redirect : { name: 'home' },
				},
				right: {
					type: 'none',
				},
			});
			

			// // Fetch user details
			this.isLoading = true;
			if(this.account.userProfile == null) {
				// Load existing passenger details if they aren't already in state.
				await this.fetchUserProfile()
			}
			this.paxFirstName = this.account.userProfile.paxFirstName;
			this.paxLastName = this.account.userProfile.paxLastName;
			this.coverImage = this.account.userProfile.coverImage;
			this.profileImage = this.account.userProfile.profileImage;
			this.paxGender = this.account.userProfile.paxGender;
			this.paxEmail = this.account.user.email; // Use the email from the login account, not the profile
			this.paxPhone = this.account.userProfile.paxPhone;
			this.paxPassportNumber = this.account.userProfile.paxPassportNumber;
			this.paxCountryOfIssue = this.account.userProfile.paxCountryOfIssue;
			this.paxPassportExpiry = this.account.userProfile.paxPassportExpiry;
			this.paxDob = this.account.userProfile.paxDob;

			this.isLoading = false;

		}
	}
</script>

